<script setup lang="ts">
interface Props {
  imageUrl?: string | null
  imageAlt?: string | null
  to: string
  variant?: 'default' | 'large'
  reverse?: boolean
  noSeoTitle?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    imageUrl: null,
    imageAlt: null,
    variant: 'default',
    reverse: false,
    noSeoTitle: false,
  },
)

const isDefault = computed(() => props.variant === 'default')
const isLarge = computed(() => props.variant === 'large')
</script>

<template>
  <div
    class="relative min-w-[300px] overflow-hidden rounded shadow-[inset_0_0_0_1px_rgba(226,232,240,1)] bg-white will-change-transform hover:bg-gray-50"
    :class="{
      'grid auto-rows-min': isDefault,
      'sm:grid sm:grid-cols-2 sm:items-center': isLarge,
    }"
  >
    <div
      class="relative"
      :class="{
        'order-last': isLarge && props.reverse,
      }"
    >
      <twic-img
        v-if="props.imageUrl"
        :src="useTwicPicsBlogUrl(props.imageUrl)"
        focus="auto"
        ratio="16/9"
        :alt="props.imageAlt"
      />
      <twic-img
        v-else
        src="library/media/pictures/frontend/a-class-golden-hour.jpg"
        focus="auto"
        ratio="16/9"
        alt="-"
      />
    </div>

    <div
      :class="{
        'space-y-2 p-4': isDefault,
        'space-y-1 p-4 sm:p-6 md:space-y-2 lg:px-12': isLarge,
      }"
    >
      <div
        v-if="$slots.tag"
        class="text-xs font-semibold uppercase text-pink-500"
      >
        <slot name="tag" />
      </div>
      <component :is="noSeoTitle ? 'p' : 'h3'">
        <NuxtLink
          :to="props.to"
          class="block focus-visible:z-10 focus-visible:rounded focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          <span
            class="font-semibold line-clamp-2"
            :class="{
              'text-xl leading-tight': isDefault,
              'text-xl sm:text-3xl': isLarge,
            }"
          >
            <slot name="title" />
          </span>
          <span
            class="absolute inset-0"
            aria-hidden="true"
          />
        </NuxtLink>
      </component>
      <div
        v-if="$slots.subtitle"
        :class="isLarge ? 'line-clamp-4' : 'line-clamp-3'"
      >
        <slot name="subtitle" />
      </div>
    </div>
  </div>
</template>
